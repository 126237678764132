import { useContext } from 'react';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';
import { UserContext } from '../../stores/StoreContexts';
import Credits from '../credits/Credits';
import './landing.scss';
import Sponsors from './Sponsors/Sponsors';

const Landing = () => {
  const backgroundImageStyle = {
    backgroundImage: `url(${
      process.env.REACT_APP_S3_ASSETS + 'landing.jpg?date=2022-08-10'
    })`,
    backgroundSize: 'cover',
    backgroundPosition: 'center right',
    backgroundRepeat: 'no-repeat'
  };

  const userStore = useContext(UserContext);

  return (
    <>
      {userStore.loggedIn && <Redirect to='/dashboard'></Redirect>}
      <div style={backgroundImageStyle} className='banner_background'>
        <div className='container'>
          <div
            className='flex-wrap align-items-center justify-content-center justify-content-lg-start'
            style={{ marginTop: '100px' }}
          >
            <h1 className='banner_header'>
              A Digital Spray Plan <br></br> Solution for New <br></br> Zealand
              Growers.
            </h1>
            <h3 className='banner_subtitle'>
              Identify the risks and undertake responsible management and
              mitigation of adverse agrichemical spray effects on people and the
              environment.
            </h3>
          </div>
        </div>
      </div>
      <Container fluid className='bg-primary text-white'>
        <Container className='py-4'>
          <Row>
            <Col md={8}>
              <h5>The SprayPlan Manager platform has been updated.</h5>
              If you are an existing user of the original system{' '}
              <Link className='text-white' to='/login'>
                <b>login to access your Spray Plans</b>
              </Link>{' '}
              or{' '}
              <a className='text-white' href='mailto:support@hortplus.com'>
                <b>get in touch</b>
              </a>
              .
            </Col>
          </Row>
        </Container>
      </Container>
      <Container className='mb-5'>
        <Row>
          <Col className='mt-5'>
            <h1>Create Property Spray Plans &amp; Identify the Risks</h1>
            <p>
              Property Spray Plans summarise how growers, farmers, and
              contractors can responsibly manage the risk of agrichemical use.
              Spray Plans are a tool to manage and minimise the adverse effects
              of agrichemical use on people and the environment.
            </p>
            <p>
              Property Spray Plans are a legislative requirement in New Zealand
              through Regional Council Air Plans and a requirement under NZS
              8409:2021. They are also a valuable tool for GLOBAL G.A.P. and
              demonstrate to customers the responsible use of agrichemicals.
            </p>
            <p className='pb-2'>
              <b>
                SprayPlan Manager can assist you in creating and maintaining a
                digital Spray Plan for your property.
              </b>
            </p>
            <hr />
            <h4 className='pt-2'>A Property Spray Plan vs a Spray Diary.</h4>
            <p>
              Property Spray Plans and a Spray Diary are different. A Spray Plan
              is prepared prior to spray application (usually on a annual
              basis). Its purpose is to identify strategies to mitigate the
              risks of agrichemical use. A Spray Diary is a detailed record of
              your agrichemical usage completed after each application. It
              focusses on recording in detail what you applied to which crops,
              when you did it, and how you actually managed the risks.
            </p>
          </Col>
          <Col className='text-center d-flex justify-content-center align-items-center mt-5'>
            <ul className='list-unstyled'>
              <li>
                <Image
                  src={process.env.REACT_APP_S3_ASSETS + 'growsafe-tick.jpg'}
                  style={{ maxWidth: '80%' }}
                  alt='Growsafe'
                />
              </li>
              <li>
                <Image
                  src={process.env.REACT_APP_S3_ASSETS + 'global-gap.png'}
                  alt='GLOBAL G.A.P.'
                />
              </li>
              <li>
                <Image
                  src={process.env.REACT_APP_S3_ASSETS + 'nz-gap.png'}
                  alt='NZ GAP'
                />
              </li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col md={6} className='mt-3'>
            <h1>Features</h1>
            <ul>
              <li>
                Managed and up-to-date product database of New Zealand
                registered and unregistered agrichemicals.
              </li>
              <li>Create comprehensive Spray Plan reports.</li>
              <li>Create HSNO risk reports.</li>
              <li>
                Generate interactive maps of hazards and sensitive areas in
                properties and production areas.
              </li>
              <li>
                Export printable reports identifying sensitive area, hazards,
                and management actions.
              </li>
              <li>
                Create Spray Plans that meet regulatory and industry
                requirements.
              </li>
              <li>Suitable for all crops &amp; industries.</li>
              <li>Suitable for both small and large operations.</li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col md={6} className='mt-4'>
            <h3>Need More Functionality?</h3>
            <p>
              We build custom platforms and tools for agriculture and the
              primary sector and can integrate custom functionality into
              SprayPlan Manager to meet your requirements.
            </p>
            <Button variant='primary'>
              <a
                className='text-decoration-none text-white'
                href='mailto:support@hortplus.com'
              >
                Get in Touch
              </a>
            </Button>
          </Col>
        </Row>
        <Row>
          <Col md={6} className='mt-5'>
            <h3>Need a Spray Diary Solution?</h3>
            <p>
              Try our powerful and light-weight Spray Diary Solution, SprayLog.
            </p>
            <Button
              href='https://www.hortplus.com/spraylog/'
              variant='primary'
              target='_blank'
            >
              Learn more about SprayLog
            </Button>
          </Col>
        </Row>
        <Row>
          <Col md={6} className='mt-5'>
            <h3 className='mb-3'>Need Growsafe Training and Support?</h3>
            <Image
              className='mb-3'
              src={process.env.REACT_APP_S3_ASSETS + 'proactive-education.png'}
              alt='Proactive Education'
            />
            <p>
              ProActive Education provides fun, informative and interactive
              courses for people to help them in the safe, responsible and
              effective use of agrichemicals. We can run tailored courses to
              suit your individual needs as a company, groups of companies or
              industry sector.
            </p>
            <Button
              href='https://www.proactiveeducation.co.nz/'
              variant='primary'
              target='_blank'
            >
              Visit Proactive Education
            </Button>
          </Col>
        </Row>
        <Row>
          <Col md={6} className='mt-5'>
            <h3 className='mb-3'>Growsafe Endorsed</h3>
            <Button
              href='https://www.growsafe.co.nz/GrowSafe/About_Us/Growsafe_Endorsed/GrowSafe/AboutUs/GrowsafeEndorsed.aspx?hkey=660b68c4-f3b6-4260-84d4-866e3ff6b476'
              variant='primary'
              target='_blank'
            >
              Visit Growsafe Endorsed
            </Button>
          </Col>
        </Row>
      </Container>
      <Sponsors />
      <Credits />
    </>
  );
};

export default Landing;
