import { debounce } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import Heading from '../Heading';
import {
  Action,
  ActionKind,
  Delcaration
} from '../utils/riskAssessmentReducer';

interface Props {
  declaration: Delcaration | null;
  dispatch: React.Dispatch<Action>;
}

const RiskAssessmentDeclaration = ({
  declaration,
  dispatch
}: Props): JSX.Element => {
  const textboxChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const datetime = new Date();
      const date = datetime.toLocaleDateString('en-GB');
      const time = datetime.toLocaleTimeString();
      dispatch({
        type: ActionKind.SET_DECLARATION,
        payload: { name: e.target.value, date: `${date} @ ${time}` }
      });
    },
    [dispatch]
  );

  const debounceChange = useMemo(
    () => debounce(textboxChange, 400),
    [textboxChange]
  );

  return (
    <div className='p-4' style={{ backgroundColor: '#f2f2f2' }}>
      <Heading level='h6'>
        <b>I declare this risk assessment is correct!</b>
      </Heading>
      <Row>
        <Col>
          <input
            ref={(input) => {
              if (input) input.value = declaration?.name || '';
            }}
            className='form-control'
            placeholder='Sign Name'
            defaultValue={declaration?.name}
            onChange={debounceChange}
          />
        </Col>
        <Col></Col>
      </Row>
    </div>
  );
};

export default React.memo(RiskAssessmentDeclaration);
