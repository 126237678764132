import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { Container, Row, Col, Alert } from 'react-bootstrap';
import PropertyStore from '../../stores/PropertyStore';
import { UserPropertyContext } from '../../stores/StoreContexts';
import Heading from './Heading';

interface Props {
  display: boolean;
}

const FrontPage = ({ display }: Props): JSX.Element => {
  const userPropertyStore = useContext<PropertyStore>(UserPropertyContext);
  const property = userPropertyStore.selectedProperty;

  return (
    <Container className='frontpage mb-4'>
      <Row>
        <Col xs={!display && 6}>
          <Container>
            <Row>
              <Heading>Property Details</Heading>
            </Row>
            <Row>
              <span>
                <b>ID/Number:</b> {property?.property_id || ''}
              </span>
            </Row>
            <Row>
              <span>
                <b>Name:</b> {property?.property_name || ''}
              </span>
            </Row>
            <Row>
              <span>
                <b>Address:</b> {property?.address || ''}
              </span>
            </Row>
            <Row>
              <span>
                <b>Region:</b> {property?.region?.name || ''}
              </span>
            </Row>
          </Container>
        </Col>
        <Col xs={!display && 6}>
          <Container>
            <Row>
              <Heading>Production Area</Heading>
            </Row>
            <Row>
              <span>
                <b>Production Type:</b> {property?.property_id || ''}
              </span>
            </Row>
            <Row>
              <span>
                <b>Name:</b> {property?.property_name || ''}
              </span>
            </Row>
            <Row>
              <span>
                <b>Area:</b> {property?.address || ''}
              </span>
            </Row>
          </Container>
        </Col>
      </Row>
      <Row className='mt-3'>
        <Col>
          <Alert variant='info'>
            This report is a summary of the hazards assosicated with the
            products that are planned to be used on this property and production
            area above. These hazards are derived from the Hazardous Substances
            and New Organisms Act 1996 (HSNO) classifications assosicated with
            each product e.g 6.1B, 9.2A etc.
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col>
          <hr />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col className='frontpage-list'>
          <h5 className='pb-2'>The report is split into 3 sections</h5>
          <table>
            <tr>
              <th>Section 1: </th>
              <td>
                Lists all of the products that have a 'Danger' classification
                and lists what this danger is beside each product.
              </td>
            </tr>
            <tr>
              <th>Section 2: </th>
              <td>
                Highlights all of the human toxicity hazards associated with
                each product.
              </td>
            </tr>
            <tr>
              <th>Section 3: </th>
              <td>
                Highlights all the environmental hazards associated with each
                product
              </td>
            </tr>
          </table>
        </Col>
      </Row>
    </Container>
  );
};

export default observer(FrontPage);
