import { faCheck, faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react';
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Table
} from 'react-bootstrap';
import { UserPropertyContext } from '../../../stores/StoreContexts';
import { ApiPut } from '../../../utils/utils';
import { errorToast } from '../../helpers/toasts/ToastUtils';
import AddPersonnel from '../../modals/AddPersonnel';
import PersonnelForm from './forms/PersonnelForm';

function PersonnelCard() {
  const userPropertyStore = useContext(UserPropertyContext);
  const property = userPropertyStore.selectedProperty;
  const personnel = userPropertyStore.selectedProperty?.property_personnel
    ? userPropertyStore.selectedProperty?.property_personnel
    : [];

  const [addPersonnel, setAddPersonnel] = useState(false);
  const [editing, setEditing] = useState(false);

  const headerColour = userPropertyStore
    .missingSprayPlanComponents(property)
    .includes('Spray personnel')
    ? 'alert-warning'
    : '';

  return editing ? (
    <Formik
      enableReinitialize
      initialValues={{ property_personnel: [...personnel] }}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true);
        ApiPut(
          'property/' + userPropertyStore.selectedPropertyId + '/personnel',
          values
        )
          .then((res) => {
            if (res.ok) userPropertyStore.getPersonnel();
            else {
              errorToast('Something went wrong');
            }
          })
          .finally(() => {
            setEditing(false);
            actions.setSubmitting(false);
          });
      }}
    >
      {(props) => (
        <Form onSubmit={props.handleSubmit}>
          <Card border='muted'>
            <Card.Header
              className={`d-flex justify-content-between align-items-center text-dark ${headerColour}`}
            >
              <h5 className='m-0'>5. Spray Personnel</h5>
              <div>
                {/* Submit button */}
                <Button variant='link' className='text-success' type='submit'>
                  <FontAwesomeIcon icon={faCheck} />
                </Button>
                {/* Cancel button */}
                <Button
                  variant='link'
                  className='text-danger'
                  type='button'
                  disabled={props.isSubmitting}
                  onClick={() => setEditing(false)}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </Button>
              </div>
            </Card.Header>
            <Card.Body>
              <Alert
                className='rounded-0 text-dark mt-1'
                variant='info'
                style={{ marginTop: 10 }}
              >
                Contact information for each of the properties spray personnel
                along with their certification e.g. growsafe, approved handler.
              </Alert>
              <PersonnelForm {...props}></PersonnelForm>
            </Card.Body>
          </Card>
        </Form>
      )}
    </Formik>
  ) : (
    <>
      <Card id='spraypersonnel' border='muted'>
        <Card.Header
          className={`d-flex justify-content-between align-items-center text-dark ${headerColour}`}
        >
          <h5 className='m-0'>5. Spray Personnel</h5>
          {personnel.length > 0 ? (
            <Button
              variant='link'
              type='button'
              onClick={() => setEditing(true)}
            >
              <FontAwesomeIcon icon={faEdit} />
            </Button>
          ) : null}
        </Card.Header>
        <Card.Body>
          <Alert
            className='rounded-0 text-dark mt-1'
            variant='info'
            style={{ marginTop: 10 }}
          >
            Contact information for each of the properties spray personnel along
            with their certification e.g. growsafe, approved handler.
          </Alert>
          <Row>
            <Col>
              <Container>
                <div>
                  <ul className='list-unstyled'>
                    {personnel?.length === 0 || undefined || null ? (
                      <p className='empty-card-text'>No personnel added</p>
                    ) : (
                      <>
                        {personnel?.map((person, index) => (
                          <Container key={person.id}>
                            <Row>
                              <Col>
                                <Row>
                                  <b>Operator</b>
                                </Row>
                                <Row>
                                  <span>
                                    Name:{' '}
                                    {personnel[index].name || (
                                      <i style={{ color: 'gray' }}>
                                        (blank name)
                                      </i>
                                    )}
                                  </span>
                                </Row>
                                <Row>
                                  <span>
                                    Phone:{' '}
                                    {personnel[index].phone || (
                                      <i style={{ color: 'gray' }}>
                                        (blank phone)
                                      </i>
                                    )}
                                  </span>
                                </Row>
                                <Row>
                                  <span>
                                    Email:{' '}
                                    {personnel[index].email || (
                                      <i style={{ color: 'gray' }}>
                                        (blank email)
                                      </i>
                                    )}
                                  </span>
                                </Row>
                              </Col>
                            </Row>
                            <br></br>
                            <Row>
                              <Container>
                                <Row>
                                  <b>Certifications</b>
                                </Row>
                              </Container>
                              {/** Only display table if there are certifications. */}
                              {person.personnel_certifications &&
                              person.personnel_certifications.length > 0 ? (
                                <Table striped hover size='sm' responsive>
                                  <thead>
                                    <tr>
                                      <th>Certificate</th>
                                      <th>Number/Code</th>
                                      <th>Expiry Date</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {person.personnel_certifications?.map(
                                      (certificate, i) => (
                                        <tr key={i}>
                                          <td>
                                            {certificate.name || (
                                              <i style={{ color: 'gray' }}>
                                                (blank certificate)
                                              </i>
                                            )}
                                          </td>
                                          <td>
                                            {certificate.number || (
                                              <i style={{ color: 'gray' }}>
                                                (blank number/code)
                                              </i>
                                            )}
                                          </td>
                                          <td>
                                            {certificate.expire || (
                                              <i style={{ color: 'gray' }}>
                                                (blank expiry date)
                                              </i>
                                            )}
                                          </td>
                                        </tr>
                                      )
                                    ) || <tr></tr>}
                                  </tbody>
                                </Table>
                              ) : (
                                <i className='mt-2'>No Certifications Added</i>
                              )}
                            </Row>
                            <hr />
                          </Container>
                        ))}
                      </>
                    )}
                  </ul>
                </div>
                <div className='add-button'>
                  <Button
                    variant='outline-success'
                    onClick={() => {
                      setAddPersonnel(!addPersonnel);
                    }}
                  >
                    Add Personnel
                  </Button>
                </div>
              </Container>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <AddPersonnel showModal={addPersonnel} setShowModal={setAddPersonnel} />
    </>
  );
}

export default observer(PersonnelCard);
