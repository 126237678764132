import { observer } from 'mobx-react-lite';
import React from 'react';
import { Col, Container, Row, Table } from 'react-bootstrap';
import DangerProductsTable from './DangerProductsTable';
import Heading from './Heading';
import { useHSNOsData } from './utils';

const InitialInfo = (): JSX.Element => {
  const [dangerHSNOs, productHSNOs] = useHSNOsData();

  return (
    <Container className='initialinfo mb-4'>
      <Row className='mb-5'>
        <Col>
          <Heading>No Information</Heading>
          <p>
            Our product database has no HSNO classifications for the following
            products, please refer to the labels or MSDS sheets for safe and
            proper use.
          </p>
          <Table striped hover size='sm' responsive>
            <thead>
              <tr>
                <th>Product</th>
                <th>Type</th>
                <th>Manufacturer</th>
                <th>Active Checmicals</th>
              </tr>
            </thead>
            <tbody>
              {productHSNOs?.map((list, i) => {
                const product = list.product;
                const hsnos = list.hsnos;
                if (hsnos.length === 0) {
                  return (
                    <tr key={i}>
                      <td>{product.name}</td>
                      <td>
                        {product.use_types.length > 0 ? (
                          product.use_types.map((type, i) => {
                            return (
                              <div key={i}>
                                <i style={{ color: 'gray' }}>{type.name}</i>
                              </div>
                            );
                          })
                        ) : (
                          <i style={{ color: 'gray' }}>(blank type)</i>
                        )}
                      </td>
                      <td>
                        {product?.licensee ? (
                          <i style={{ color: 'gray' }}>
                            {product?.licensee.name}
                          </i>
                        ) : (
                          <i style={{ color: 'gray' }}>(blank licensee)</i>
                        )}
                      </td>
                      <td>
                        {product.actives.length > 0 ? (
                          product.actives.map((active, i) => {
                            return (
                              <div key={i}>
                                <i style={{ color: 'gray' }}>
                                  {`${active?.chemical?.name} (${
                                    active?.content
                                  } ${active?.unit?.symbol || '<no units>'})`}
                                </i>
                              </div>
                            );
                          })
                        ) : (
                          <i style={{ color: 'gray' }}>(blank actives)</i>
                        )}
                      </td>
                    </tr>
                  );
                } else {
                  return <React.Fragment key={i}></React.Fragment>;
                }
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row>
        <Col>
          <DangerProductsTable dangerHSNOs={dangerHSNOs} />
        </Col>
      </Row>
    </Container>
  );
};

export default observer(InitialInfo);
