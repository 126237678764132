import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import PropertyStore from '../../stores/PropertyStore';
import { UserPropertyContext } from '../../stores/StoreContexts';
import { useData } from '../../utils/useData';
import { ApiDelete } from '../../utils/utils';
import SprayPlanReportButton from '../dashboard/dashboard_card/SprayPlanReportButton';
import LoadingOverlay from '../helpers/loading_overlay/LoadingOverlay';
import ConfirmationModal from '../helpers/modals/ConfirmationModal';
import { errorToast } from '../helpers/toasts/ToastUtils';
import IncompleteAlert from './IncompleteAlert';
import GeneralNotesCard from './property_cards/GeneralNotesCard';
import MapCard from './property_cards/MapCard';
import NeighboursCard from './property_cards/NeighboursCard';
import PersonnelCard from './property_cards/PersonnelCard';
import ProductionCard from './property_cards/ProductionCard';
import PropertyInfoCard from './property_cards/PropertyInfoCard';
import SensitiveCard from './property_cards/SensitiveCard';

const Properties = (): JSX.Element => {
  const userPropertyStore = useContext<PropertyStore>(UserPropertyContext);
  const property = userPropertyStore.selectedProperty;
  const history = useHistory();
  let { propertyId }: any = useParams();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  useData(propertyId);

  useEffect(() => {
    if (!userPropertyStore.selectedProperty) {
      userPropertyStore.setSelectedProperty(Number(propertyId));
    }
  }, [userPropertyStore, propertyId]);

  return (
    <>
      {userPropertyStore.loading ? (
        <LoadingOverlay />
      ) : (
        <Container className='px-3 py-3'>
          <Row className='mb-4'>
            <Col>
              <Button
                className='pt-3'
                variant='link'
                onClick={() => history.push('/dashboard')}
              >
                <FontAwesomeIcon icon={faArrowLeft} /> Properties
              </Button>
            </Col>
            <Col className='text-center'>
              <h2>{userPropertyStore.selectedProperty?.property_name}</h2>
            </Col>
            <Col className='text-center pt-3'>
              <div style={{ float: 'right' }}>
                <SprayPlanReportButton
                  userPropertyStore={userPropertyStore}
                  details={userPropertyStore.selectedProperty}
                />
              </div>
            </Col>
          </Row>
          {!userPropertyStore.isSprayPlanReady(Number(propertyId)) ? (
            <IncompleteAlert property={property} />
          ) : null}
          <Row className='align-items-center my-3'>
            <Col>
              <PropertyInfoCard />
            </Col>
          </Row>
          <Row className='align-items-center my-3'>
            <Col>
              <MapCard />
            </Col>
          </Row>
          <Row className='align-items-center my-3'>
            <Col>
              <SensitiveCard />
            </Col>
          </Row>
          <Row className='align-items-center my-3'>
            <Col>
              <NeighboursCard />
            </Col>
          </Row>
          <Row className='align-items-center my-3'>
            <Col>
              <PersonnelCard />
            </Col>
          </Row>
          <Row className='align-items-center my-3'>
            <Col>
              <ProductionCard />
            </Col>
          </Row>
          <Row className='align-items-center my-3'>
            <Col>
              <GeneralNotesCard />
            </Col>
          </Row>
          <Row>
            {' '}
            <Col className='text-center pt-3 mb-3'>
              <Button
                variant='outline-danger link'
                onClick={() => setShowDeleteModal(true)}
              >
                Delete Property
              </Button>
              <ConfirmationModal
                title='Confirm delete?'
                body={
                  <p>
                    Do you wish to delete the property:{' '}
                    <b>{userPropertyStore.selectedProperty?.property_name}</b>?
                    This cannot be undone!`
                  </p>
                }
                confirmBtnText='Delete'
                confirmBtnVariant='danger'
                showModal={showDeleteModal}
                setShowModal={setShowDeleteModal}
                confirmAction={async () => {
                  const res = await ApiDelete(
                    'property/' + userPropertyStore.selectedPropertyId
                  );
                  if (res && res.ok) {
                    const data = await res.json();
                    userPropertyStore.properties =
                      userPropertyStore.properties?.filter((property) => {
                        return property.id !== data.id;
                      });
                    history.goBack();
                  } else {
                    errorToast('Something went wrong');
                  }
                }}
              ></ConfirmationModal>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default observer(Properties);
