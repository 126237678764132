import { observer } from 'mobx-react-lite';
import { Container } from 'react-bootstrap';
import { PropertyBlock } from '../../../models/userTypes';
import Hazards from '../../sprayplan_report/production_areas/Hazards';

interface Props {
  productionArea: PropertyBlock | null | undefined;
}

const ReportHazards = ({ productionArea }: Props): JSX.Element => {
  return (
    <Container>
      <h5 className='mb-3 font-weight-bold'>Hazards</h5>
      {!productionArea ? (
        <p>No production area to show hazards</p>
      ) : (
        <Hazards productionArea={productionArea} />
      )}
    </Container>
  );
};

export default observer(ReportHazards);
