import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useRef, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import PropertyStore from '../../stores/PropertyStore';
import { UserPropertyContext } from '../../stores/StoreContexts';
import LoadingOverlay from '../helpers/loading_overlay/LoadingOverlay';
import EnvironmentToxicity from './EnvironmentToxicity';
import FrontPage from './FrontPage';
import './hazard_report.scss';
import HumanToxicity from './HumanToxicity';
import InitialInfo from './InitialInfo';

const HazardReport = (): JSX.Element => {
  const reportRef = useRef<HTMLDivElement | null>(null);
  const [display, setDisplay] = useState(true);
  const userPropertyStore = useContext<PropertyStore>(UserPropertyContext);
  const { propertyId, productionId }: any = useParams();

  useEffect(() => {
    userPropertyStore.fetchHazardReportData(
      Number(propertyId),
      Number(productionId)
    );
  }, [propertyId, productionId, userPropertyStore]);

  const handlePrint = useReactToPrint({
    content: () => reportRef.current,
    onBeforeGetContent: () => setDisplay(false),
    onBeforePrint: () => setDisplay(false),
    onAfterPrint: () => setDisplay(true),
    documentTitle: 'Hazard Report'
  });

  return (
    <>
      {userPropertyStore.loadingHazardReport ? (
        <LoadingOverlay />
      ) : (
        <Container className='mb-4' ref={reportRef}>
          <style type='text/css' media='print'>
            {'\
              @page { size: landscape; }\
            '}
          </style>
          <Container className='px-3 py-3'>
            <Row>
              <Col></Col>
              <Col className='text-center'>
                <h3 className='main-heading'>Hazard Report</h3>
              </Col>
              {display ? (
                <Col>
                  <Button
                    variant='primary'
                    style={{ float: 'right' }}
                    onClick={handlePrint}
                  >
                    Print
                  </Button>
                </Col>
              ) : (
                <Col></Col>
              )}
            </Row>
          </Container>
          <Container className='mt-4'>
            <Row>
              <Col>
                <FrontPage display={display} />
              </Col>
            </Row>
          </Container>
          <div className='page-break' />
          <Container>
            <Row>
              <Col>
                <InitialInfo />
              </Col>
            </Row>
          </Container>
          <div className='page-break' />
          <Container>
            <Row>
              <Col>
                <HumanToxicity />
              </Col>
            </Row>
          </Container>
          <div className='page-break' />
          <Container>
            <Row>
              <Col>
                <EnvironmentToxicity />
              </Col>
            </Row>
          </Container>
        </Container>
      )}
    </>
  );
};

export default observer(HazardReport);
