import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import { useContext, useState } from 'react';
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Row,
  Spinner,
  Table
} from 'react-bootstrap';
import { useParams } from 'react-router';
import { PropertyBlock } from '../../../../models/userTypes';
import { UserPropertyContext } from '../../../../stores/StoreContexts';
import AddSprayPlan from '../../../modals/AddSprayPlan';
import CopySprays from '../../../modals/CopySprays/CopySprays';

interface Props {
  productionArea: PropertyBlock | undefined;
  editing: boolean;
  setEditing: React.Dispatch<React.SetStateAction<boolean>>;
}

const SprayPlanCardView = ({
  productionArea,
  editing,
  setEditing
}: Props): JSX.Element => {
  const userPropertyStore = useContext(UserPropertyContext);
  const [addSprayPlan, setAddSprayPlan] = useState(false);
  let { propertyId, productionId }: any = useParams();

  return (
    <Card border='muted'>
      <Card.Header className='d-flex justify-content-between align-items-center'>
        <h5 className='m-0'>2. Spray Product List</h5>
        {productionArea?.block_sprays &&
          productionArea.block_sprays.length > 0 && (
            <Button
              variant='link'
              type='button'
              onClick={() => setEditing(!editing)}
            >
              <FontAwesomeIcon icon={faEdit} />
            </Button>
          )}
      </Card.Header>
      <Card.Body>
        <Alert
          className='rounded-0 text-dark mt-1'
          variant='info'
          style={{ marginTop: 10 }}
        >
          Add the spray product, the purpose, the number of applications and
          aproximate timing. Create a list of all the spray products you apply
          on this production area, including the typical or expected number of
          applications in a season, the target(s) of the applications and the
          timing. The timing can be specific or a rough estimate e.g. spring,
          after wetness events, etc.
        </Alert>
        {productionArea?.block_sprays ? (
          productionArea?.block_sprays!.length === 0 ? (
            <Container>
              <div>
                <p className='empty-card-text'>No spray products listed</p>
              </div>
            </Container>
          ) : (
            <Table striped hover size='sm' responsive>
              <thead>
                <tr>
                  <th>Product Name</th>
                  <th>Number of Applications</th>
                  <th>Target</th>
                  <th>Timing</th>
                </tr>
              </thead>
              <tbody>
                {(productionArea?.block_sprays &&
                  productionArea?.block_sprays.map((areas, i) => (
                    <tr key={i}>
                      <td>
                        {userPropertyStore.products?.filter(
                          (product) => product.licence_no === areas.licence_no
                        )[0]?.name || (
                            <i style={{ color: 'gray' }}>
                              ({areas.licence_no})
                            </i>
                          ) || (
                            <i style={{ color: 'gray' }}>(blank prod. name)</i>
                          )}
                      </td>
                      <td>
                        {areas.applications || (
                          <i style={{ color: 'gray' }}>(blank applications)</i>
                        )}
                      </td>
                      <td>
                        {areas.target || (
                          <i style={{ color: 'gray' }}>(blank target)</i>
                        )}
                      </td>
                      <td>
                        {areas.timing || (
                          <i style={{ color: 'gray' }}>(blank timing)</i>
                        )}
                      </td>
                    </tr>
                  ))) || <tr></tr>}
              </tbody>
            </Table>
          )
        ) : (
          <Spinner animation='border' />
        )}
        <Row>
          <Col></Col>
          <Col className='text-center' sm={4} md={4}>
            <AddSprayPlan propertyId={propertyId} productionId={productionId} />
          </Col>
          <Col className='text-center' sm={4} md={4}>
            <CopySprays propertyId={propertyId} productionId={productionId} />
          </Col>
          <Col></Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default observer(SprayPlanCardView);
