import { faCheck, faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import { useContext, useState } from 'react';
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
  Table
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import PropertyStore from '../../../stores/PropertyStore';
import { UserPropertyContext } from '../../../stores/StoreContexts';
import { ApiDelete, ApiPut } from '../../../utils/utils';
import LoadingOverlay from '../../helpers/loading_overlay/LoadingOverlay';
import { errorToast } from '../../helpers/toasts/ToastUtils';
import AddHazard from '../../modals/AddHazard';
import HazardForm from './forms/HazardForm';
import HazardReportButton from '../../dashboard/dashboard_card/HazardReportButton';

const HazardsCard = (): JSX.Element => {
  const userPropertyStore = useContext<PropertyStore>(UserPropertyContext);
  let { propertyId, productionId }: any = useParams();
  let productionArea = userPropertyStore.properties
    ?.filter((property) => property.id === Number(propertyId))[0]
    ?.property_blocks!.filter((area) => area.id === Number(productionId))[0];
  const block_hazards = productionArea?.block_hazards
    ? productionArea.block_hazards
    : [];

  const [addHazard, setAddHazard] = useState(false);
  const [editing, setEditing] = useState(false);

  return editing ? (
    <Formik
      initialValues={{ block_hazards: [...block_hazards] }}
      enableReinitialize
      onSubmit={(data, { setSubmitting }) => {
        setSubmitting(true);
        ApiPut(
          `property/${propertyId}/production-area/${productionId}/block-hazards`,
          { block_hazards: data.block_hazards }
        )
          .then((res) => {
            if (!res.ok) {
              errorToast('Something went wrong');
            }
          })
          .finally(() => {
            userPropertyStore.getProductionAreaDetails(propertyId);
            setEditing(false);
            setSubmitting(false);
          });
      }}
    >
      {(props) => (
        <Form onSubmit={props.handleSubmit}>
          <Card border='muted'>
            <Card.Header className='d-flex justify-content-between align-items-center text-dark'>
              <h5 className='m-0'>4. Hazards</h5>
              <div>
                {props.isSubmitting ? (
                  <LoadingOverlay />
                ) : (
                  <>
                    {' '}
                    <Button
                      variant='link'
                      className='text-success'
                      type='submit'
                    >
                      <FontAwesomeIcon icon={faCheck} />
                    </Button>
                    <Button
                      variant='link'
                      className='text-danger'
                      type='button'
                      disabled={props.isSubmitting}
                      onClick={() => setEditing(false)}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </Button>
                  </>
                )}
              </div>
            </Card.Header>
            <Card.Body>
              <Alert
                className='rounded-0 text-dark mt-1'
                variant='info'
                style={{ marginTop: 10 }}
              >
                <p>
                  <b>Hazards are about the products that are used.</b>
                </p>
                <p>
                  The Hazard Report creates a summary of the hazards associated
                  with this production area's Spray Plan, based on HSNO
                  classifications.
                </p>
                <p>
                  Open the Hazard Report and use it to help identify the
                  toxicity of the products and detail how you plan to manage
                  each of them.
                </p>
                <p>
                  The main hazards are <b>worker exposure</b> during mixing and
                  application, and <b>environmental toxicity</b> on water, soil,
                  bees, birds and animals.
                </p>
              </Alert>
              <Row>
                <Col style={{ flexGrow: 0.82, marginBottom: 10 }}>
                  <b>Description</b>
                </Col>
                <Col style={{ marginBottom: 10 }}>
                  <b>Management</b>
                </Col>
              </Row>
              <HazardForm
                propertyId={propertyId}
                productionId={productionId}
                {...props}
              />
              <hr></hr>
            </Card.Body>
          </Card>
        </Form>
      )}
    </Formik>
  ) : (
    <>
      <Card border='muted'>
        <Card.Header className='d-flex justify-content-between align-items-center text-dark'>
          <h5 className='m-0'>4. Hazards</h5>
          {productionArea?.block_hazards &&
            productionArea.block_hazards.length > 0 && (
              <Button
                variant='link'
                type='button'
                onClick={() => setEditing(!editing)}
              >
                <FontAwesomeIcon icon={faEdit} />
              </Button>
            )}
        </Card.Header>
        <Card.Body>
          <Alert
            className='rounded-0 text-dark mt-1'
            variant='info'
            style={{ marginTop: 10 }}
          >
            <p>
              <b>Hazards are about the products that are used.</b>
            </p>
            <p>
              The Hazard Report creates a summary of the hazards associated with
              this production area's Spray Plan, based on HSNO classifications.
            </p>
            <p>
              Open the Hazard Report and use it to help identify the toxicity of
              the products and detail how you plan to manage each of them.
            </p>
            <p>
              The main hazards are <b>worker exposure</b> during mixing and
              application, and <b>environmental toxicity</b> on water, soil,
              bees, birds and animals.
            </p>
          </Alert>

          {productionArea?.block_hazards ? (
            productionArea?.block_hazards!.length === 0 ? (
              <Container>
                <div className='empty-card-text'>
                  <p>No Hazards listed</p>
                </div>
              </Container>
            ) : (
              <Table striped hover size='sm' responsive>
                <thead>
                  <tr>
                    <th>Description</th>
                    <th>Management</th>
                  </tr>
                </thead>
                <tbody>
                  {(productionArea?.block_hazards &&
                    productionArea?.block_hazards.map((areas, i) => (
                      <tr key={i}>
                        <td>
                          {areas.description || (
                            <i style={{ color: 'gray' }}>(blank description)</i>
                          )}
                        </td>
                        <td>
                          {areas.management || (
                            <i style={{ color: 'gray' }}>(blank management)</i>
                          )}
                        </td>
                      </tr>
                    ))) || <tr></tr>}
                </tbody>
              </Table>
            )
          ) : (
            <Spinner animation='border' />
          )}
          <div className='add-button'>
            <HazardReportButton />
            <Button
              variant='outline-success'
              onClick={() => {
                setAddHazard(!addHazard);
              }}
            >
              Add Hazard
            </Button>
          </div>
        </Card.Body>
      </Card>

      <AddHazard
        showModal={addHazard}
        setShowModal={setAddHazard}
        propertyId={propertyId}
        productionId={productionId}
      />
    </>
  );
};

export const deleteHazard = (
  block_hazard_id: number,
  propertyId: number,
  productionId: number,
  userPropertyStore: PropertyStore
) => {
  ApiDelete(
    `property/${propertyId}/production-area/${productionId}/block-hazard/${block_hazard_id}`
  ).then((res) => {
    if (res.ok) {
      userPropertyStore.getProductionAreaDetails(propertyId);
    } else {
      errorToast('Something went wrong');
    }
  });
};

export default observer(HazardsCard);
