import { faCheck, faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { User } from '../../models/userTypes';
import { UserContext } from '../../stores/StoreContexts';
import { ApiPut } from '../../utils/utils';
import { errorToast } from '../helpers/toasts/ToastUtils';

interface Values {
  name: string | undefined;
  email: string | undefined;
}

const UserDetails = (): JSX.Element => {
  const [editing, setEditing] = useState(false);
  const userStore = useContext(UserContext);

  const initialValues: Values = {
    name: userStore.user?.name,
    email: userStore.user?.email
  };

  return editing ? (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true);
        ApiPut('user/update', values)
          .then((res) => {
            if (res.ok) return res.json();
            else {
              errorToast('Something went wrong');
              setEditing(false);
            }
          })
          .then((data) => {
            if (data) {
              userStore.user = data as User;
              setEditing(false);
            }
          })
          .finally(() => {
            actions.setSubmitting(false);
          });
      }}
    >
      {(props) => (
        <Form onSubmit={props.handleSubmit}>
          <Card border='muted'>
            <Card.Header className='d-flex justify-content-between align-items-center'>
              Details
              <div>
                {/* Submit button */}
                <Button variant='link' className='text-success' type='submit'>
                  <FontAwesomeIcon icon={faCheck} />
                </Button>

                {/* Cancel button */}
                <Button
                  variant='link'
                  className='text-danger'
                  type='button'
                  disabled={props.isSubmitting}
                  onClick={() => setEditing(false)}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </Button>
              </div>
            </Card.Header>
            <Card.Body>
              <Form.Group controlId='name'>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type='string'
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values.name}
                  placeholder='Name'
                />
              </Form.Group>
              <Form.Group controlId='email'>
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type='email'
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values.email}
                  placeholder='Email Address'
                />
              </Form.Group>
            </Card.Body>
          </Card>
        </Form>
      )}
    </Formik>
  ) : (
    <Card border='muted'>
      <Card.Header className='d-flex justify-content-between align-items-center'>
        Details
        <Button variant='link' type='button' onClick={() => setEditing(true)}>
          <FontAwesomeIcon icon={faEdit} />
        </Button>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col> Name:</Col>
          <Col>{userStore.user?.name}</Col>
        </Row>
        <Row>
          <Col>Email:</Col>
          <Col>{userStore.user?.email}</Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default observer(UserDetails);
