import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { Col, Table } from 'react-bootstrap';
import { PropertyBlock, UserProperty } from '../../../../models/userTypes';
import { UserPropertyContext } from '../../../../stores/StoreContexts';

interface Props {
  property: UserProperty | null | undefined;
  productionArea: PropertyBlock | null | undefined;
}

const PropertyDetails = ({ property, productionArea }: Props) => {
  const userPropertyStore = useContext(UserPropertyContext);

  return (
    <>
      <Col>
        <Table className='property-details'>
          <thead>
            <tr>
              <th className='text-primary' colSpan={2}>
                Property Details
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className='font-weight-bold'>ID/Number</td>
              <td>{property?.property_id || ''}</td>
            </tr>
            <tr>
              <td className='font-weight-bold'>Name</td>
              <td>{property?.property_name || ''}</td>
            </tr>
            <tr>
              <td className='font-weight-bold'>Region</td>
              <td>{property?.region?.name || ''}</td>
            </tr>
            <tr>
              <td className='font-weight-bold'>Address</td>
              <td>{property?.address || ''}</td>
            </tr>
          </tbody>
        </Table>
      </Col>
      <Col>
        <Table className='productionarea-details'>
          <thead>
            <tr>
              <th className='text-primary' colSpan={2}>
                Production Area Details
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className='font-weight-bold'>Production Type / Land Use</td>
              <td>
                {userPropertyStore.productions?.filter(
                  (production) =>
                    production.id === productionArea?.production_id
                )[0]?.type || <i style={{ color: 'gray' }}>(blank)</i>}
              </td>
            </tr>
            <tr>
              <td className='font-weight-bold'>Production Area Name</td>
              <td>
                {productionArea?.name || (
                  <i style={{ color: 'gray' }}>(blank)</i>
                )}
              </td>
            </tr>
            <tr>
              <td className='font-weight-bold'>Land Area (Hectares)</td>
              <td>
                {productionArea?.area
                  ? `${productionArea.area} (ha)`
                  : '' || <i style={{ color: 'gray' }}>(blank)</i>}
              </td>
            </tr>
            <tr>
              <td className='font-weight-bold'>Review Date</td>
              <td>
                {(productionArea?.review_date &&
                  new Date(productionArea.review_date).toLocaleDateString(
                    'en-NZ',
                    {
                      day: 'numeric',
                      month: 'long',
                      year: 'numeric'
                    }
                  )) || <i style={{ color: 'gray' }}>(blank)</i>}
              </td>
            </tr>
          </tbody>
        </Table>
      </Col>
    </>
  );
};

export default observer(PropertyDetails);
