import { faCheck, faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import { useContext, useState } from 'react';
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Table
} from 'react-bootstrap';
import { UserPropertyContext } from '../../../stores/StoreContexts';
import { ApiPut } from '../../../utils/utils';
import { errorToast } from '../../helpers/toasts/ToastUtils';
import AddSensitiveArea from '../../modals/AddSensitiveArea';
import SensitiveForm from './forms/SensitiveForm';

const SensitiveCard = (): JSX.Element => {
  const userPropertyStore = useContext(UserPropertyContext);
  const property = userPropertyStore.selectedProperty;
  const sensitives = userPropertyStore.selectedProperty?.property_sensitives
    ? userPropertyStore.selectedProperty?.property_sensitives
    : [];

  const [addSensitiveArea, setAddSensitiveArea] = useState(false);
  const [editing, setEditing] = useState(false);

  const headerColour = userPropertyStore
    .missingSprayPlanComponents(property)
    .includes('Sensitive areas')
    ? 'alert-warning'
    : '';

  return editing ? (
    <Formik
      enableReinitialize
      initialValues={{ property_sensitives: [...sensitives] }}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true);
        ApiPut(
          'property/' +
            userPropertyStore.selectedPropertyId +
            '/sensitive-areas',
          values
        )
          .then((res) => {
            if (res.ok) userPropertyStore.getSensitives();
            else {
              errorToast('Something went wrong');
            }
          })
          .finally(() => {
            setEditing(false);
            actions.setSubmitting(false);
          });
      }}
    >
      {(props) => (
        <Form onSubmit={props.handleSubmit}>
          <Card border='muted'>
            <Card.Header
              className={`d-flex justify-content-between align-items-center text-dark ${headerColour}`}
            >
              <h5 className='m-0'>3. Sensitive Areas</h5>
              <div>
                {/* Submit button */}
                <Button variant='link' className='text-success' type='submit'>
                  <FontAwesomeIcon icon={faCheck} />
                </Button>

                {/* Cancel button */}
                <Button
                  variant='link'
                  className='text-danger'
                  type='button'
                  disabled={props.isSubmitting}
                  onClick={() => setEditing(false)}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </Button>
              </div>
            </Card.Header>
            <Card.Body>
              <Alert
                className='rounded-0 text-dark mt-1'
                variant='info'
                style={{ marginTop: 10 }}
              >
                Sensitive areas on and around the property and how they will be
                managed.
              </Alert>
              <SensitiveForm {...props}></SensitiveForm>
            </Card.Body>
          </Card>
        </Form>
      )}
    </Formik>
  ) : (
    <>
      <Card id='sensitiveareas' border='muted'>
        <Card.Header
          className={`d-flex justify-content-between align-items-center text-dark ${headerColour}`}
        >
          <h5 className='m-0'>3. Sensitive Areas</h5>
          {sensitives.length > 0 ? (
            <Button
              variant='link'
              type='button'
              onClick={() => setEditing(true)}
            >
              <FontAwesomeIcon icon={faEdit} />
            </Button>
          ) : null}
        </Card.Header>
        <Card.Body>
          <Alert
            className='rounded-0 text-dark mt-1'
            variant='info'
            style={{ marginTop: 10 }}
          >
            Sensitive areas on and around the property and how they will be
            managed.
          </Alert>
          <Row>
            <Col>
              <Container>
                <div>
                  <ul className='list-unstyled'>
                    {sensitives?.length === 0 || undefined || null ? (
                      <p className='empty-card-text'>
                        No sensitive areas added
                      </p>
                    ) : (
                      <Table striped hover size='sm' responsive>
                        <thead>
                          <tr>
                            <th>Description</th>
                            <th>Management</th>
                          </tr>
                        </thead>
                        <tbody>
                          {sensitives?.map((areas, i) => (
                            <tr key={i}>
                              <td>
                                {areas.description || (
                                  <i style={{ color: 'gray' }}>
                                    (blank description)
                                  </i>
                                )}
                              </td>
                              <td>
                                {areas.management || (
                                  <i style={{ color: 'gray' }}>
                                    (blank management)
                                  </i>
                                )}
                              </td>
                            </tr>
                          )) || <tr></tr>}
                        </tbody>
                      </Table>
                    )}
                  </ul>
                </div>
                <div className='add-button'>
                  <Button
                    variant='outline-success'
                    onClick={() => {
                      setAddSensitiveArea(!addSensitiveArea);
                    }}
                  >
                    Add Sensitive Areas
                  </Button>
                </div>
              </Container>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <AddSensitiveArea
        showModal={addSensitiveArea}
        setShowModal={setAddSensitiveArea}
        sensitives={sensitives || 0}
      />
    </>
  );
};

export default observer(SensitiveCard);
