import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik } from 'formik';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { ApiPost } from '../../utils/utils';
import FormField from '../helpers/forms/FormField';
import { errorToast, successToast } from '../helpers/toasts/ToastUtils';

const ForgotPassword = (): JSX.Element => {
  const history = useHistory();
  return (
    <Container className='mt-5 px-3 py-2'>
      <Row>
        <Col>
          <Button
            className='pt-3'
            variant='link'
            onClick={() => history.goBack()}
          >
            <FontAwesomeIcon icon={faArrowLeft} /> Back
          </Button>
        </Col>
        <Col md={6} className='text-center'>
          <h1>Reset password</h1>
          <p>
            Enter your email address below and a password reset email will be
            sent to you.
          </p>
        </Col>
        <Col></Col>
      </Row>
      <Row className='mt-3' style={{ justifyContent: 'center' }}>
        <Col className='mb-5' md={6}>
          <Card>
            <Card.Body>
              <Formik
                initialValues={{}}
                onSubmit={(values, actions) => {
                  actions.setSubmitting(true);
                  ApiPost('user/forgot-password', values)
                    .then((res) => {
                      if (res.ok) {
                        successToast('Email successfully sent');
                        history.push('/login');
                      } else {
                        errorToast('Something went wrong');
                      }
                    })
                    .finally(() => {
                      actions.setSubmitting(false);
                    });
                }}
              >
                {(props) => (
                  <Form className='text-center' onSubmit={props.handleSubmit}>
                    <Form.Group>
                      <FormField
                        fieldAs='input'
                        fieldName='email'
                        type='email'
                        placeholder='Email Address'
                      />
                    </Form.Group>
                    <Button variant='outline-primary' type='submit'>
                      Reset Password
                    </Button>
                  </Form>
                )}
              </Formik>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ForgotPassword;
