import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Popup
  // @ts-ignore
} from '@urbica/react-map-gl';
import { Button } from 'react-bootstrap';
import { Feature, FeatureCollection } from '../../../models/types';
import './MapLabels.scss';

interface Props {
  popupLatLng: [number, number] | null;
  selectedFeatureIndex: number;
  setSelectedFeatureIndex: (value: React.SetStateAction<number>) => void;
  collection: FeatureCollection;
  setEditPropertyPlace: (value: React.SetStateAction<boolean>) => void;
  setPropertyPlaceToEdit: (value: React.SetStateAction<Feature | null>) => void;
  enableEdit: boolean;
}

const MapLabels = ({
  popupLatLng,
  selectedFeatureIndex,
  setSelectedFeatureIndex,
  collection,
  setEditPropertyPlace,
  setPropertyPlaceToEdit,
  enableEdit
}: Props): JSX.Element => {
  const display =
    popupLatLng &&
    selectedFeatureIndex > -1 &&
    collection.features[selectedFeatureIndex] &&
    collection.features[selectedFeatureIndex].geometry.type !== 'Polygon';

  return (
    <>
      {display && enableEdit ? (
        <Popup
          latitude={popupLatLng[0]}
          longitude={popupLatLng[1]}
          closeButton={true}
          closeOnClick={false}
          onClose={() => setSelectedFeatureIndex(-1)}
        >
          <div className='mt-1 p-1'>
            <Button
              size='sm'
              className='mr-2'
              onClick={() => {
                setEditPropertyPlace(true);
                setPropertyPlaceToEdit(
                  collection.features[selectedFeatureIndex]
                );
              }}
            >
              <FontAwesomeIcon icon={faEdit} />
            </Button>
            <span style={{ fontSize: '15px' }}>
              {collection.features[selectedFeatureIndex].properties.name}
            </span>
          </div>
        </Popup>
      ) : (
        !enableEdit && (
          <>
            {collection.features.map((feature: Feature) => {
              if (feature.geometry.type === 'Point') {
                const lat = feature.geometry.coordinates[1];
                const lng = feature.geometry.coordinates[0];
                return (
                  <Popup
                    key={feature.id}
                    latitude={lat}
                    longitude={lng}
                    closeButton={false}
                    closeOnClick={false}
                  >
                    <span style={{ fontSize: '14px' }}>
                      {feature.properties.name}
                    </span>
                  </Popup>
                );
              }
              return null;
            })}
          </>
        )
      )}
    </>
  );
};

export default MapLabels;
