import { observer } from 'mobx-react-lite';
import { Container, Row } from 'react-bootstrap';
import Heading from './Heading';
import HTTable from './tables/HTTable';

const HumanToxicity = (): JSX.Element => {
  return (
    <Container className='mb-4'>
      <Row>
        <Heading>HSNO Class 6 - Human Toxicity</Heading>
        <HTTable />
      </Row>
    </Container>
  );
};

export default observer(HumanToxicity);
