import { Container } from 'react-bootstrap';

interface Props {
  forecastImpact: string | undefined;
}

const ReportForecastImpact = ({ forecastImpact }: Props): JSX.Element => {
  return (
    <Container>
      <h5 className='mb-3 font-weight-bold'>
        Expected Weather Forecast &amp; Impact
      </h5>
      <textarea
        className='form-control'
        rows={8}
        value={forecastImpact}
        disabled
      />
    </Container>
  );
};

export default ReportForecastImpact;
