import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { useReactToPrint } from 'react-to-print';
import { PropertyBlock, UserProperty } from '../../../models/userTypes';
import RiskAssessmentMap from '../../risk-assessment/RiskAssessmentMap/RiskAssessmentMap';
import PropertyDetails from './PropertyDetails/PropertyDetails';
import './report-property.scss';
interface Props {
  property: UserProperty | null | undefined;
  productionArea: PropertyBlock | null | undefined;
  sprayDate: string | undefined;
  sprayTime: string | undefined;
  reportRef: React.MutableRefObject<HTMLDivElement | null>;
  setDisplay: React.Dispatch<React.SetStateAction<boolean>>;
  display: boolean;
}

const ReportProperty = ({
  property,
  productionArea,
  sprayDate,
  sprayTime,
  reportRef,
  setDisplay,
  display
}: Props): JSX.Element => {
  const history = useHistory();
  const handlePrint = useReactToPrint({
    content: () => reportRef.current,
    onBeforeGetContent: () => setDisplay(false),
    onBeforePrint: () => setDisplay(false),
    onAfterPrint: () => setDisplay(true),
    documentTitle: 'Risk Assessment Report'
  });

  return (
    <Container>
      <Row>
        {display ? (
          <Col className='text-center'>
            <Button
              className='mt-3'
              variant='link'
              onClick={() => history.push('/risk-assessment')}
            >
              <FontAwesomeIcon icon={faArrowLeft} /> Back
            </Button>
          </Col>
        ) : (
          <Col></Col>
        )}
        <Col className='text-center' md={8}>
          <h2>Risk Assessment for {property?.property_name}</h2>
        </Col>
        {display ? (
          <Col className='text-center'>
            <Button className='mt-3' variant='primary' onClick={handlePrint}>
              Print
            </Button>
          </Col>
        ) : (
          <Col></Col>
        )}
      </Row>
      <Row className='mb-4'>
        <Col></Col>
        <Col className='text-center' md={6}>
          <h4 className='bg-warning mt-2 p-2'>
            Spraying on <b>{sprayDate}</b> from <b>{sprayTime}</b>
          </h4>
        </Col>
        <Col></Col>
      </Row>
      <Row className='mb-4 ml-6'>
        <PropertyDetails property={property} productionArea={productionArea} />
      </Row>
      <Row>
        <Col md>{property && <RiskAssessmentMap />}</Col>
      </Row>
    </Container>
  );
};

export default ReportProperty;
