import { observer } from 'mobx-react-lite';
import { Container, Row } from 'react-bootstrap';
import Heading from './Heading';
import ETTable from './tables/ETTable';

const EnvironmentToxicity = (): JSX.Element => {
  return (
    <Container>
      <Row>
        <Heading>HSNO Class 9 - Environment Toxicity</Heading>
        <ETTable />
      </Row>
    </Container>
  );
};

export default observer(EnvironmentToxicity);
