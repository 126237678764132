import { faExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import { Table } from 'react-bootstrap';
import { ProductHSNOs } from '../../models/propertyTypes';
import Heading from './Heading';

interface Props {
  dangerHSNOs: ProductHSNOs[] | undefined;
}

const DangerProductsTable = ({ dangerHSNOs }: Props) => {
  return (
    <>
      <Heading>
        <FontAwesomeIcon icon={faExclamation} />
        <span> Dangerous Products</span>
      </Heading>
      <p>
        The following products need special attention because of their HSNO
        classification.
      </p>
      <Table striped hover size='sm' responsive>
        <thead>
          <tr>
            <th>Product</th>
            <th>Dangers</th>
          </tr>
        </thead>
        <tbody>
          {dangerHSNOs?.map((danger, i) => {
            const product = danger.product;
            const hsnos = danger.hsnos;
            return (
              <tr key={i}>
                <td>{product.name}</td>
                <td>
                  {hsnos.map((hsno, i) => {
                    return <p key={i}>- {hsno.description}</p>;
                  })}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

export default observer(DangerProductsTable);
