import { faCheck, faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import { useContext, useState } from 'react';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { UserPropertyContext } from '../../../stores/StoreContexts';
import { ApiPut } from '../../../utils/utils';
import { errorToast } from '../../helpers/toasts/ToastUtils';
import GeneralNotesForm from './forms/GeneralNotesForm';

export interface GeneralNotesBody {
  region_id: number | undefined;
  general_notes: string;
}

const GeneralNotesCard = (): JSX.Element => {
  const userPropertyStore = useContext(UserPropertyContext);
  const [editing, setEditing] = useState(false);
  const generalNotes = userPropertyStore.selectedProperty?.general_notes;

  const initialValues: GeneralNotesBody = {
    region_id: userPropertyStore.selectedProperty?.region_id,
    general_notes: generalNotes || ''
  };

  return editing ? (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true);
        ApiPut('property/' + userPropertyStore.selectedPropertyId, values)
          .then((res) => {
            if (res.ok) userPropertyStore.getProperty();
            else {
              errorToast('Something went wrong');
            }
          })
          .finally(() => {
            setEditing(false);
            actions.setSubmitting(false);
          });
      }}
    >
      {(props) => (
        <Form onSubmit={props.handleSubmit}>
          <Card border='muted'>
            <Card.Header className='d-flex justify-content-between align-items-center'>
              <h5 className='m-0'>7. General Notes</h5>
              <div>
                {/* Submit button */}
                <Button variant='link' className='text-success' type='submit'>
                  <FontAwesomeIcon icon={faCheck} />
                </Button>

                {/* Cancel button */}
                <Button
                  variant='link'
                  className='text-danger'
                  type='button'
                  disabled={props.isSubmitting}
                  onClick={() => setEditing(false)}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </Button>
              </div>
            </Card.Header>
            <Card.Body>
              <GeneralNotesForm {...props} />
            </Card.Body>
          </Card>
        </Form>
      )}
    </Formik>
  ) : (
    <Card border='muted'>
      <Card.Header className='d-flex justify-content-between align-items-center'>
        <h5 className='m-0'>7. General Notes</h5>
        <Button variant='link' type='button' onClick={() => setEditing(true)}>
          <FontAwesomeIcon icon={faEdit} />
        </Button>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <Container>
              <div>
                <p>{generalNotes}</p>
              </div>
            </Container>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default observer(GeneralNotesCard);
