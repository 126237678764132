import { faArrowLeft, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useRef } from 'react';
import { Alert, Button, Col, Container, Row } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { Feature } from '../../models/types';
import PropertyStore from '../../stores/PropertyStore';
import { UserPropertyContext } from '../../stores/StoreContexts';
import { useData } from '../../utils/useData';
import { useMultiBoundary } from '../../utils/useMultiBoundary';
import ManagerDetails from './ManagerDetails';
import Neighbours from './Neighbours';
import OwnerDetails from './OwnerDetails';
import Personnel from './Personnel';
import PMap from './PMap';
import ProductionAreas from './production_areas/ProductionAreas';
import PropertyDetails from './PropertyDetails';
import SensitiveAreas from './SensitiveAreas';
import './sprayplan-report.scss';

const Heading = ({ children }: { children: string }) => {
  return (
    <h5 className='heading'>
      <b>{children}</b>
    </h5>
  );
};

const SparyPlanReport = () => {
  const history = useHistory();
  let { propertyId } = useParams<{ propertyId: string }>();
  const userPropertyStore = useContext<PropertyStore>(UserPropertyContext);
  const reportRef = useRef<HTMLDivElement | null>(null);
  // Map data.
  const objectBoundaries = useMultiBoundary();
  const objectPropertyPlaces = userPropertyStore.selectedProperty
    ?.propertyPlaces as unknown as Feature[];
  const imageObject = userPropertyStore.selectedProperty?.property_image;
  const mapType = userPropertyStore.selectedProperty?.map_type;

  useData(Number(propertyId));

  useEffect(() => {
    if (!userPropertyStore.products) userPropertyStore.getProducts();
  }, [propertyId, userPropertyStore]);

  const handlePrint = useReactToPrint({
    content: () => reportRef.current
  });

  return (
    <Container ref={reportRef}>
      <Container className='px-3 py-3'>
        <Row className='hide-on-print'>
          <Col>
            <Alert className='mt-3 mb-4' variant='info'>
              <FontAwesomeIcon icon={faInfoCircle} size='lg' />
              <span className='ml-2'>
                To save this as a PDF file, select the PDF option when printing
                the page.
              </span>
            </Alert>
          </Col>
        </Row>
        <Row>
          <Col className='hide-on-print' xs={2}>
            <Button
              className='pt-1'
              variant='link'
              onClick={() => history.goBack()}
            >
              <FontAwesomeIcon icon={faArrowLeft} /> Back
            </Button>
          </Col>
          <Col className='text-center'>
            <h3 className='title'>Property Spray Plan</h3>
          </Col>
          <Col className='hide-on-print' xs={2}>
            <Button
              variant='primary'
              style={{ float: 'right' }}
              onClick={handlePrint}
            >
              Print
            </Button>
          </Col>
        </Row>
      </Container>
      <Container className='px-5'>
        <Row className='pb-5'>
          <Col>
            <Heading>Property Map</Heading>
            <hr></hr>
            <PMap
              objectBoundaries={objectBoundaries}
              objectPropertyPlaces={objectPropertyPlaces}
              imageObject={imageObject}
              mapType={mapType}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Heading>Property Details</Heading>
            <hr></hr>
            <PropertyDetails />
          </Col>
          <Col>
            <Heading>Owner Details</Heading>
            <hr></hr>
            <OwnerDetails />
          </Col>
        </Row>
        <Row className='pb-5 mt-4'>
          <Col></Col>
          <Col>
            <Heading>Manager Details</Heading>
            <hr></hr>
            <ManagerDetails />
          </Col>
        </Row>
        <Row className='pb-5'>
          <Col>
            <Heading>Sensitive Areas</Heading>
            <hr></hr>
            <SensitiveAreas />
          </Col>
        </Row>
        <Row className='pb-5'>
          <Col>
            <Heading>Neighbours</Heading>
            <hr></hr>
            <Neighbours />
          </Col>
        </Row>
        <Row className='pb-5'>
          <Col>
            <Heading>Spray Personnel</Heading>
            <hr></hr>
            <Personnel />
          </Col>
        </Row>
        <Row className='pb-5'>
          <Col>
            <Heading>Production Areas</Heading>
            <hr></hr>
            <ProductionAreas />
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default observer(SparyPlanReport);
