import { faCheck, faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react';
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Table
} from 'react-bootstrap';
import { UserPropertyContext } from '../../../stores/StoreContexts';
import { ApiPut } from '../../../utils/utils';
import { errorToast } from '../../helpers/toasts/ToastUtils';
import AddNeighbours from '../../modals/AddNeighbours';
import NeighboursForm from './forms/NeighboursForm';

const NeighboursCard = (): JSX.Element => {
  const userPropertyStore = useContext(UserPropertyContext);
  const property = userPropertyStore.selectedProperty;
  const neighbours = userPropertyStore.selectedProperty?.property_neighbours
    ? userPropertyStore.selectedProperty?.property_neighbours
    : [];

  const [addNeighbour, setAddNeighbour] = useState(false);
  const [editing, setEditing] = useState(false);

  const headerColour = userPropertyStore
    .missingSprayPlanComponents(property)
    .includes('Neighbours')
    ? 'alert-warning'
    : '';

  return editing ? (
    <Formik
      enableReinitialize
      initialValues={{ property_neighbours: [...neighbours] }}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true);
        ApiPut(
          'property/' + userPropertyStore.selectedPropertyId + '/neighbours',
          values
        )
          .then((res) => {
            if (res.ok) userPropertyStore.getNeighbours();
            else {
              errorToast('Something went wrong');
            }
          })
          .finally(() => {
            setEditing(false);
            actions.setSubmitting(false);
          });
      }}
    >
      {(props) => (
        <Form onSubmit={props.handleSubmit}>
          <Card border='muted'>
            <Card.Header
              className={`d-flex justify-content-between align-items-center text-dark ${headerColour}`}
            >
              <h5 className='m-0'>4. Neighbours</h5>
              <div>
                {/* Submit button */}
                <Button variant='link' className='text-success' type='submit'>
                  <FontAwesomeIcon icon={faCheck} />
                </Button>
                {/* Cancel button */}
                <Button
                  variant='link'
                  className='text-danger'
                  type='button'
                  disabled={props.isSubmitting}
                  onClick={() => setEditing(false)}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </Button>
              </div>
            </Card.Header>
            <Card.Body>
              <Alert
                className='rounded-0 text-dark mt-1'
                variant='info'
                style={{ marginTop: 10 }}
              >
                Spray Plans should be physically printed and shared in person
                with neighbours at least once a year. Refer to your property map
                to make sure all the neighbours are added.
              </Alert>
              <NeighboursForm {...props}></NeighboursForm>
            </Card.Body>
          </Card>
        </Form>
      )}
    </Formik>
  ) : (
    <>
      <Card id='neighbours' border='muted'>
        <Card.Header
          className={`d-flex justify-content-between align-items-center text-dark ${headerColour}`}
        >
          <h5 className='m-0'>4. Neighbours</h5>
          {neighbours.length > 0 ? (
            <Button
              variant='link'
              type='button'
              onClick={() => setEditing(true)}
            >
              <FontAwesomeIcon icon={faEdit} />
            </Button>
          ) : null}
        </Card.Header>
        <Card.Body>
          <Alert
            className='rounded-0 text-dark mt-1'
            variant='info'
            style={{ marginTop: 10 }}
          >
            Spray Plans should be physically printed and shared in person with
            neighbours at least once a year. Refer to your property map to make
            sure all the neighbours are added.
          </Alert>
          <Row>
            <Col>
              <Container>
                <div>
                  <ul className='list-unstyled'>
                    {neighbours?.length === 0 || undefined || null ? (
                      <p className='empty-card-text'>No neighbours added</p>
                    ) : (
                      <Table striped hover size='sm' responsive>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Address</th>
                            <th>Phone</th>
                            <th>Email</th>
                          </tr>
                        </thead>
                        <tbody>
                          {neighbours?.map((neighbours, i) => (
                            <tr key={i}>
                              <td>
                                {neighbours.name || (
                                  <i style={{ color: 'gray' }}>(blank name)</i>
                                )}
                              </td>
                              <td>
                                {neighbours.address || (
                                  <i style={{ color: 'gray' }}>
                                    (blank address)
                                  </i>
                                )}
                              </td>
                              <td>
                                {neighbours.phone || (
                                  <i style={{ color: 'gray' }}>(blank phone)</i>
                                )}
                              </td>
                              <td>
                                {neighbours.email || (
                                  <i style={{ color: 'gray' }}>(blank email)</i>
                                )}
                              </td>
                            </tr>
                          )) || <tr></tr>}
                        </tbody>
                      </Table>
                    )}
                  </ul>
                </div>
                <div className='add-button'>
                  <Button
                    variant='outline-success'
                    onClick={() => {
                      setAddNeighbour(!addNeighbour);
                    }}
                  >
                    Add Neighbours
                  </Button>
                </div>
              </Container>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <AddNeighbours showModal={addNeighbour} setShowModal={setAddNeighbour} />
    </>
  );
};

export default observer(NeighboursCard);
