import { faCheck, faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import { useContext, useState } from 'react';
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
  Table
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Method } from '../../../models/propertyTypes';
import { UserPropertyContext } from '../../../stores/StoreContexts';
import { ApiPut } from '../../../utils/utils';
import LoadingOverlay from '../../helpers/loading_overlay/LoadingOverlay';
import { errorToast } from '../../helpers/toasts/ToastUtils';
import { createHeaders, createInitialValues } from './ProductionCardUtils';
import './production_cards.scss';

const Empty = (): JSX.Element => {
  return (
    <td className='empty-td' colSpan={3}>
      <i style={{ color: 'grey' }}>{'(Please Add Application Methods)'}</i>
    </td>
  );
};

const OtherApplication = ({
  children
}: {
  children: JSX.Element | JSX.Element[];
}): JSX.Element => {
  return (
    <Container
      className='p-3'
      style={{
        border: '1px solid',
        borderRadius: '10px',
        borderColor: '#DFDFDF'
      }}
    >
      <Row>
        <Col>
          <h6>
            <b>Other Application Equipment</b>
          </h6>
          {children}
        </Col>
      </Row>
    </Container>
  );
};

const ApplicationCard = (): JSX.Element => {
  const userPropertyStore = useContext(UserPropertyContext);
  const [editing, setEditing] = useState(false);
  let { propertyId, productionId }: any = useParams();
  let productionArea = userPropertyStore.properties
    ?.filter((property) => property.id === Number(propertyId))[0]
    ?.property_blocks!.filter((area) => area.id === Number(productionId))[0];

  let headers = createHeaders(productionArea);
  const initialValues = createInitialValues(headers, productionArea);

  return editing ? (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={(data, { setSubmitting }) => {
        const sprays = data.sprays;
        let newData = sprays.filter((obj) => {
          if (
            obj &&
            obj.methodId &&
            obj.methodId !== 0 &&
            obj.sprays &&
            obj.sprays.length > 0
          ) {
            return { methodId: obj.methodId, sprays: obj.sprays };
          }
          return null;
        });
        setSubmitting(true);
        ApiPut(
          `property/${propertyId}/production-area/${productionId}/application-methods`,
          {
            applicationMethods: newData,
            otherApplication: data.otherApplication
          }
        )
          .then((res) => {
            if (res.ok) {
              userPropertyStore.getProductionAreaDetails(propertyId);
            } else
              errorToast(
                'Something went wrong. Please check your data and ensure you have not selected duplicate application methods.'
              );
          })
          .finally(() => {
            setSubmitting(false);
            setEditing(false);
          });
      }}
    >
      {({
        isSubmitting,
        handleSubmit,
        setFieldValue,
        values,
        errors,
        touched
      }) => (
        <Form onSubmit={handleSubmit}>
          <Card border='muted'>
            <Card.Header className='d-flex justify-content-between align-items-center'>
              <h5 className='m-0'>3. Application Methods</h5>
              <div>
                {isSubmitting ? (
                  <LoadingOverlay />
                ) : (
                  <>
                    <Button
                      variant='link'
                      className='text-success'
                      type='submit'
                    >
                      <FontAwesomeIcon icon={faCheck} />
                    </Button>
                    <Button
                      variant='link'
                      className='text-danger'
                      type='button'
                      disabled={isSubmitting}
                      onClick={() => {
                        headers = createHeaders(productionArea);
                        setEditing(false);
                      }}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </Button>
                  </>
                )}
              </div>
            </Card.Header>
            <Card.Body>
              <Alert
                className='rounded-0 text-dark mt-1'
                variant='info'
                style={{ marginTop: 10 }}
              >
                Select an application method from the drop down box and tick the
                corresponding products you will apply using that method.
              </Alert>
              {productionArea?.block_hazards ? (
                <Container className='p-0'>
                  <Row>
                    <Col>
                      <Table striped size='sm' responsive>
                        <thead>
                          <tr>
                            <th>Sprays</th>
                            {headers.map((headerId, index) => {
                              return (
                                <th key={headerId}>
                                  <Field
                                    as='select'
                                    name={`sprays.[${index}].methodId`}
                                    className='form-control'
                                    onChange={(e: any) => {
                                      // Set method manually to display or hide other
                                      // application if selected.
                                      setFieldValue(
                                        `sprays.[${index}].methodId`,
                                        Number(e.target.value)
                                      );
                                    }}
                                  >
                                    <option value={0}>
                                      -- Select a method --
                                    </option>
                                    {userPropertyStore.methods?.map(
                                      (method: Method) => {
                                        return (
                                          <option
                                            key={`method_${method.id}`}
                                            value={method.id}
                                          >
                                            {method.name}
                                          </option>
                                        );
                                      }
                                    )}
                                  </Field>
                                </th>
                              );
                            })}
                            {[...Array(3 - headers.length)].map((_, i) => (
                              <th key={i}>
                                <Field
                                  as='select'
                                  name={`sprays.[${
                                    headers.length + i
                                  }].methodId`}
                                  className='form-control'
                                  onChange={(e: any) => {
                                    // Set method manually to display or hide other
                                    // application if selected.
                                    setFieldValue(
                                      `sprays.[${headers.length + i}].methodId`,
                                      Number(e.target.value)
                                    );
                                  }}
                                >
                                  <option value={0}>
                                    -- Select a method --
                                  </option>
                                  {userPropertyStore.methods?.map(
                                    (method: Method) => (
                                      <option
                                        key={`method_${method.id}`}
                                        value={method.id}
                                      >
                                        {method.name}
                                      </option>
                                    )
                                  )}
                                </Field>
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {(productionArea?.block_sprays &&
                            productionArea?.block_sprays.map((block, i) => (
                              <tr key={i}>
                                {
                                  <td>
                                    {userPropertyStore.products?.filter(
                                      (product) =>
                                        product.licence_no === block.licence_no
                                    )[0]?.name || (
                                        <i style={{ color: 'gray' }}>
                                          ({block.licence_no})
                                        </i>
                                      ) || (
                                        <i style={{ color: 'gray' }}>
                                          (blank prod. name)
                                        </i>
                                      )}
                                  </td>
                                }
                                {headers.map((_, index) => (
                                  <td
                                    key={index}
                                    style={{ textAlign: 'center' }}
                                  >
                                    <Field
                                      type='checkbox'
                                      name={`sprays.${index}.sprays`}
                                      value={`${block.id}`}
                                    ></Field>
                                  </td>
                                ))}
                                {[...Array(3 - headers.length)].map((_, i) => (
                                  <td key={i} style={{ textAlign: 'center' }}>
                                    <Field
                                      type='checkbox'
                                      name={`sprays.${
                                        headers.length + i
                                      }.sprays`}
                                      value={`${block.id}`}
                                    ></Field>
                                  </td>
                                ))}
                              </tr>
                            ))) || <tr></tr>}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                  {/** Displays the "Other" application method if selected */}
                  {values.sprays.find((s) => s.methodId === 10) && (
                    <Row>
                      <Col>
                        <OtherApplication>
                          <p>
                            You have selected the "Other" option above, please
                            specify the spray application method you use.
                          </p>
                          <hr />
                          <Field
                            className='form-control'
                            style={{ width: '100%' }}
                            as='input'
                            name='otherApplication'
                            validate={(value: string) => {
                              let error;
                              if (!value || value === '') {
                                error =
                                  'Please enter your spray application method you use.';
                              }
                              return error;
                            }}
                          />
                          {errors.otherApplication &&
                          touched.otherApplication ? (
                            <div style={{ color: 'red' }}>
                              {errors.otherApplication}
                            </div>
                          ) : (
                            <></>
                          )}
                        </OtherApplication>
                      </Col>
                    </Row>
                  )}
                </Container>
              ) : (
                <Spinner animation='border' />
              )}
            </Card.Body>
          </Card>
        </Form>
      )}
    </Formik>
  ) : (
    <Card border='muted'>
      <Card.Header className='d-flex justify-content-between align-items-center'>
        <h5 className='m-0'>3. Application Methods</h5>
        {productionArea?.block_sprays &&
          productionArea?.block_sprays.length > 0 && (
            <Button
              variant='link'
              type='button'
              onClick={() => setEditing(!editing)}
            >
              <FontAwesomeIcon icon={faEdit} />
            </Button>
          )}
      </Card.Header>
      <Card.Body>
        <Alert
          className='rounded-0 text-dark mt-1'
          variant='info'
          style={{ marginTop: 10 }}
        >
          Select an application method from the drop down box and tick the
          corresponding products you will apply using that method.
        </Alert>
        {productionArea?.block_sprays ? (
          productionArea?.block_sprays!.length === 0 ? (
            <Container>
              <p>
                No Application Methods.<br></br> You need to create a Spray Plan
                to add an application method.
              </p>
            </Container>
          ) : (
            <Container className='p-0'>
              <Row>
                <Col>
                  <Table hover size='sm' responsive>
                    <thead>
                      <tr>
                        <th>Sprays</th>
                        {headers.length > 0 ? (
                          headers.map((headerId) => (
                            <th key={headerId}>
                              {headerId === 0 ? (
                                <></>
                              ) : (
                                userPropertyStore.methods?.filter(
                                  (method) => method.id === headerId
                                )[0].name
                              )}
                            </th>
                          ))
                        ) : (
                          <th></th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {(productionArea?.block_sprays &&
                        productionArea?.block_sprays.map((block, i) => (
                          <tr key={i}>
                            <td>
                              {userPropertyStore.products?.filter(
                                (product) =>
                                  product.licence_no === block.licence_no
                              )[0]?.name || (
                                  <i style={{ color: 'gray' }}>
                                    ({block.licence_no})
                                  </i>
                                ) || (
                                  <i style={{ color: 'gray' }}>
                                    (blank prod. name)
                                  </i>
                                )}
                            </td>
                            {headers.length > 0 &&
                            block.spray_methods &&
                            block.spray_methods.length > 0 ? (
                              headers.map((method, i) => {
                                return block.spray_methods
                                  ?.map(
                                    (methods) => methods.method_id === method
                                  )
                                  .reduce(
                                    (prev, curr) => prev || curr,
                                    false
                                  ) ? (
                                  <td key={i}>
                                    <FontAwesomeIcon icon={faCheck} />
                                  </td>
                                ) : (
                                  <td key={i}></td>
                                );
                              })
                            ) : (
                              <Empty key={i} />
                            )}
                          </tr>
                        ))) || <tr></tr>}
                    </tbody>
                  </Table>
                </Col>
              </Row>
              {/** Displays the "Other" application method if selected */}
              {headers.includes(10) && (
                <Row>
                  <Col>
                    <OtherApplication>
                      <hr />
                      <span>
                        {productionArea.other_application || 'Not selected'}
                      </span>
                    </OtherApplication>
                  </Col>
                </Row>
              )}
            </Container>
          )
        ) : (
          <Spinner animation='border' />
        )}
      </Card.Body>
    </Card>
  );
};

export default observer(ApplicationCard);
