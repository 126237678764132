import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { Container, Table } from 'react-bootstrap';
import { PropertySensitives } from '../../../models/userTypes';
import { UserPropertyContext } from '../../../stores/StoreContexts';

const ReportSensitiveAreas = (): JSX.Element => {
  const userPropertyStore = useContext(UserPropertyContext);
  const sensitives = userPropertyStore.selectedProperty?.property_sensitives
    ? userPropertyStore.selectedProperty?.property_sensitives
    : [];

  return (
    <Container>
      <h5 className='mb-3 font-weight-bold'>Sensitive Areas</h5>
      <ul className='list-unstyled'>
        {sensitives?.length === 0 || undefined || null ? (
          <p className='empty-card-text'>No sensitive areas</p>
        ) : (
          <Table striped hover size='sm' responsive>
            <thead>
              <tr>
                <th>Description</th>
                <th>Management</th>
              </tr>
            </thead>
            <tbody>
              {sensitives?.map((areas: PropertySensitives, i: number) => (
                <tr key={i}>
                  <td>
                    {areas.description || (
                      <i style={{ color: 'gray' }}>(blank description)</i>
                    )}
                  </td>
                  <td>
                    {areas.management || (
                      <i style={{ color: 'gray' }}>(blank management)</i>
                    )}
                  </td>
                </tr>
              )) || <tr></tr>}
            </tbody>
          </Table>
        )}
      </ul>
    </Container>
  );
};

export default observer(ReportSensitiveAreas);
