import { orderBy } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useContext, useState } from 'react';
import { Alert, Button, Col, Container, Row } from 'react-bootstrap';
import { UserPropertyContext } from '../../stores/StoreContexts';
import { useData } from '../../utils/useData';
import AddProperty from '../add_property/AddProperty';
import LoadingOverlay from '../helpers/loading_overlay/LoadingOverlay';
import SprayPlanGuideCard from '../sprayplan_guide_card/SprayPlanGuideCard';
import './dashboard.scss';
import DashboardCard from './dashboard_card/DashboardCard';

const Dashboard = (): JSX.Element => {
  const userPropertyStore = useContext(UserPropertyContext);
  const [addPropertyModal, setAddPropertyModal] = useState(false);
  const [showAlert, setShowAlert] = useState(true);
  useData();

  const propertiesSorted = orderBy(
    userPropertyStore.properties,
    (p) => new Date(p.created_at ? p.created_at : ''),
    ['desc']
  );

  return (
    <>
      {userPropertyStore.loading ? (
        <LoadingOverlay />
      ) : (
        <Container className='px-3 py-2'>
          <div>
            <Row>
              <Col></Col>
              <Col>
                <h2>Properties</h2>
              </Col>
              <Col className='pt-3'>
                <Button
                  className='text-right'
                  style={{ float: 'right' }}
                  variant='outline-success'
                  onClick={() => setAddPropertyModal(!addPropertyModal)}
                >
                  Add New Property
                </Button>
              </Col>
            </Row>
          </div>

          {/**
           * An alert used for new users.
           * TODO: Need a way to determine if a user is new.
           */}
          {showAlert && (
            <Alert
              className='mt-3 mb-4'
              variant='info'
              onClose={() => setShowAlert(false)}
            >
              <b>Add New Property</b> to create a Spray Plan or click the demo
              below to see what a completed Spray Plan looks like.
            </Alert>
          )}

          {propertiesSorted.map((user) => (
            <DashboardCard key={user.id} details={user} />
          ))}

          {/* Pass the functions to set modal state directly to the component. */}
          <AddProperty
            showModal={addPropertyModal}
            setShowModal={setAddPropertyModal}
          />

          <SprayPlanGuideCard />
        </Container>
      )}
    </>
  );
};

export default observer(Dashboard);
