import React, { useCallback, useContext, useEffect, useReducer } from 'react';
import { Alert, Button, Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { UserPropertyContext } from '../../stores/StoreContexts';
import { useData } from '../../utils/useData';
import { errorToast } from '../helpers/toasts/ToastUtils';
import ClearFormModal from '../modals/ClearFormModal';
import RiskAssessmentDeclaration from './RiskAssessmentDeclaration/RiskAssessmentDeclaration';
import RiskAssessmentForecast from './RiskAssessmentForecast/RiskAssessmentForecast';
import RiskAssessmentInputs from './RiskAssessmentInputs/RiskAssessmentInputs';
import RiskAssessmentMap from './RiskAssessmentMap/RiskAssessmentMap';
import RiskAssessmentProducts from './RiskAssessmentProducts/RiskAssessmentProducts';
import {
  ActionKind,
  State,
  reducer,
  reducerInitialState
} from './utils/riskAssessmentReducer';

const RiskAssessment = (): JSX.Element => {
  const userPropertyStore = useContext(UserPropertyContext);
  const riskAssessmentData = localStorage.getItem('risk-assessment') as string;
  const initialState =
    (JSON.parse(riskAssessmentData) as State) || reducerInitialState();
  const [state, dispatch] = useReducer(reducer, initialState);
  const dispatchMemoized = useCallback(dispatch, [dispatch]);
  const history = useHistory();
  useData(state.property?.id);

  useEffect(() => {
    userPropertyStore.getProducts();
  }, [userPropertyStore]);

  const validateData = () => {
    if (!state.property || !state.productionArea || !state.declaration) {
      return false;
    }
    if (!state.declaration.name || state.declaration.name.length === 0) {
      return false;
    }
    if (
      !state.sprayDate ||
      state.sprayDate.length === 0 ||
      !state.sprayTime ||
      state.sprayTime.length === 0 ||
      !state.forecastImpact ||
      state.forecastImpact.length === 0
    ) {
      return false;
    }
    return true;
  };

  return (
    <Container className='px-3 py-2'>
      <div>
        <Row className='mb-4'>
          <Col></Col>
          <Col md={10}>
            <h2>Risk Assessment</h2>
          </Col>
          <Col></Col>
        </Row>
        <Row className='mb-1'>
          <Col>
            <Alert variant='primary'>
              Fill out the form below to complete a risk assessment no earlier
              than 48 hours prior to spraying.
            </Alert>
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col md>
            <RiskAssessmentInputs
              property={state.property}
              sprayDate={state.sprayDate}
              sprayTime={state.sprayTime}
              productionArea={state.productionArea}
              dispatch={dispatchMemoized}
            />
          </Col>
          <Col md>{state.property && <RiskAssessmentMap />}</Col>
        </Row>
        <Row className='mb-4'>
          <Col>
            <RiskAssessmentForecast
              forecastImpact={state.forecastImpact}
              dispatch={dispatchMemoized}
            />
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col>
            <RiskAssessmentProducts
              products={state.products}
              productionArea={state.productionArea}
              dispatch={dispatchMemoized}
            />
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col>
            <RiskAssessmentDeclaration
              declaration={state.declaration}
              dispatch={dispatchMemoized}
            />
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col>
            <hr />
            <Row className='mb-3'>
              <Col></Col>
              <Col sm={10} className='text-center'>
                <Button
                  variant='success'
                  size='lg'
                  onClick={() => {
                    if (validateData()) {
                      localStorage.setItem(
                        'risk-assessment',
                        JSON.stringify(state)
                      );
                      history.push('/risk-assessment/report');
                    } else {
                      errorToast(
                        'Unable to save - Risk Assessment data incomplete.'
                      );
                    }
                  }}
                >
                  Save and View Report
                </Button>
              </Col>
              <Col></Col>
            </Row>
            <Row>
              <Col></Col>
              <Col sm={10} className='text-center'>
                <ClearFormModal
                  onClear={() => {
                    localStorage.removeItem('risk-assessment');
                    dispatchMemoized({
                      type: ActionKind.RESET,
                      payload: reducerInitialState()
                    });
                  }}
                />
              </Col>
              <Col></Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default React.memo(RiskAssessment);
