import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { Col, Container, Row, Table } from 'react-bootstrap';
import { PropertyBlock } from '../../../models/userTypes';
import { UserPropertyContext } from '../../../stores/StoreContexts';
import { createHeaders } from '../../production_areas/production_cards/ProductionCardUtils';

const Empty = (): JSX.Element => {
  return (
    <td className='empty-td' colSpan={3}>
      <i style={{ color: 'grey' }}>{'(Please Add Application Methods)'}</i>
    </td>
  );
};

interface Props {
  productionArea: PropertyBlock;
}

const Applications = ({ productionArea }: Props): JSX.Element => {
  const userPropertyStore = useContext(UserPropertyContext);
  let headers = createHeaders(productionArea);

  return (
    <>
      {productionArea.block_sprays &&
      productionArea.block_sprays.length === 0 ? (
        <b className='empty-section'>No Application Methods</b>
      ) : (
        <Container className='p-0'>
          <Table hover size='sm' responsive>
            <thead>
              <tr>
                <th>Sprays</th>
                {headers.length > 0 ? (
                  headers.map((headerId) => (
                    <th key={headerId}>
                      {headerId === 0 ? (
                        <></>
                      ) : (
                        userPropertyStore.methods?.filter(
                          (method) => method.id === headerId
                        )[0].name
                      )}
                    </th>
                  ))
                ) : (
                  <th></th>
                )}
              </tr>
            </thead>
            <tbody>
              {(productionArea?.block_sprays &&
                productionArea?.block_sprays.map((block, i) => (
                  <tr key={i}>
                    <td>
                      {userPropertyStore.products?.filter(
                        (product) => product.licence_no === block.licence_no
                      )[0]?.name || (
                          <i style={{ color: 'gray' }}>({block.licence_no})</i>
                        ) || (
                          <i style={{ color: 'gray' }}>(blank prod. name)</i>
                        )}
                    </td>
                    {headers.length > 0 &&
                    block.spray_methods &&
                    block.spray_methods.length > 0 ? (
                      headers.map((method, i) => {
                        return block.spray_methods
                          ?.map((methods) => methods.method_id === method)
                          .reduce((prev, curr) => prev || curr, false) ? (
                          <td key={i}>
                            <FontAwesomeIcon icon={faCheck} />
                          </td>
                        ) : (
                          <td key={i}></td>
                        );
                      })
                    ) : (
                      <Empty key={i} />
                    )}
                  </tr>
                ))) || <tr></tr>}
            </tbody>
          </Table>
          {productionArea.other_application && (
            <Container
              className='p-3'
              style={{
                border: '1px solid',
                borderRadius: '10px',
                borderColor: '#DFDFDF'
              }}
            >
              <Row>
                <Col>
                  <h6>
                    <b>Other Application Equipment</b>
                  </h6>
                  <hr />
                  <span>
                    {productionArea.other_application || 'Not selected'}
                  </span>
                </Col>
              </Row>
            </Container>
          )}
        </Container>
      )}
    </>
  );
};

export default observer(Applications);
