import { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { UserPropertyContext } from '../../stores/StoreContexts';

const OwnerDetails = (): JSX.Element => {
  const userPropertyStore = useContext(UserPropertyContext);

  return (
    <Col>
      <Row>
        <span>
          <b>Name:</b> {userPropertyStore.selectedProperty?.owner_name || ''}
        </span>
      </Row>
      <Row>
        <span>
          <b>Phone:</b> {userPropertyStore.selectedProperty?.owner_phone || ''}
        </span>
      </Row>
      <Row>
        <span>
          <b>Mobile:</b>{' '}
          {userPropertyStore.selectedProperty?.owner_mobile || ''}
        </span>
      </Row>
      <Row>
        <span>
          <b>Email:</b> {userPropertyStore.selectedProperty?.owner_email || ''}
        </span>
      </Row>
    </Col>
  );
};

export default OwnerDetails;
