import { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { UserPropertyContext } from '../../stores/StoreContexts';

const PropertyDetails = (): JSX.Element => {
  const userPropertyStore = useContext(UserPropertyContext);

  return (
    <Col>
      <Row>
        <span>
          <b>ID/Number:</b>{' '}
          {userPropertyStore.selectedProperty?.property_id || ''}
        </span>
      </Row>
      <Row>
        <span>
          <b>Name:</b> {userPropertyStore.selectedProperty?.property_name || ''}
        </span>
      </Row>
      <Row>
        <span>
          <b>Region:</b>{' '}
          {userPropertyStore.selectedProperty?.region?.name || ''}
        </span>
      </Row>
      <Row>
        <span>
          <b>Address:</b> {userPropertyStore.selectedProperty?.address || ''}
        </span>
      </Row>
    </Col>
  );
};

export default PropertyDetails;
