import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import mapboxgl from 'mapbox-gl';
import { useEffect } from 'react';

interface Props {
  mapRef: React.MutableRefObject<any>;
  accessToken: string | undefined;
  position?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
  countries: string;
  limit: number;
}

const Geocode = ({
  mapRef,
  accessToken,
  position,
  countries,
  limit
}: Props) => {
  const map: mapboxgl.Map = mapRef.current.getMap();

  useEffect(() => {
    if (accessToken) {
      const control = new MapboxGeocoder({
        accessToken,
        mapboxgl: map,
        countries,
        limit
      });

      map?.addControl(control, position);

      return () => {
        try {
          map?.removeControl(control);
        } catch {}
      };
    }
  }, [map, position, countries, limit, accessToken]);

  return null;
};

export default Geocode;
