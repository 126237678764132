import { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { UserPropertyContext } from '../../stores/StoreContexts';

const ManagerDetails = (): JSX.Element => {
  const userPropertyStore = useContext(UserPropertyContext);

  return (
    <Col>
      <Row>
        <span>
          <b>Name:</b> {userPropertyStore.selectedProperty?.manager_name || ''}
        </span>
      </Row>
      <Row>
        <span>
          <b>Phone:</b>{' '}
          {userPropertyStore.selectedProperty?.manager_phone || ''}
        </span>
      </Row>
      <Row>
        <span>
          <b>Mobile:</b>{' '}
          {userPropertyStore.selectedProperty?.manager_mobile || ''}
        </span>
      </Row>
      <Row>
        <span>
          <b>Email:</b>{' '}
          {userPropertyStore.selectedProperty?.manager_email || ''}
        </span>
      </Row>
    </Col>
  );
};

export default ManagerDetails;
