import { faCheck, faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import { useContext, useState } from 'react';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { UserPropertyContext } from '../../../stores/StoreContexts';
import { ApiPut } from '../../../utils/utils';
import { errorToast } from '../../helpers/toasts/ToastUtils';
import PropertyInfoForm from './forms/PropertyInfoForm';

function PropertyInfoCard() {
  // Property viewer card should take an object, and display relevent information
  const userPropertyStore = useContext(UserPropertyContext);
  const selectedProperty = userPropertyStore?.selectedProperty;
  const [editing, setEditing] = useState(false);

  return editing ? (
    <Formik
      initialValues={{ selectedProperty: { ...selectedProperty } }}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true);
        ApiPut(
          'property/' + userPropertyStore.selectedPropertyId,
          values.selectedProperty
        )
          .then((res) => {
            if (res.ok) return res.json();
            else {
              errorToast('Something went wrong');
              setEditing(false);
            }
          })
          .then((data) => {
            if (data) {
              userPropertyStore.getProperty();
              setEditing(false);
            }
          })
          .finally(() => {
            actions.setSubmitting(false);
          });
      }}
    >
      {(props) => (
        <Form onSubmit={props.handleSubmit}>
          <Card border='muted'>
            <Card.Header className='d-flex justify-content-between align-items-center'>
              <h5 className='m-0'>1. Property &#38; Contact Information</h5>
              <div>
                {/* Submit button */}
                <Button variant='link' className='text-success' type='submit'>
                  <FontAwesomeIcon icon={faCheck} />
                </Button>

                {/* Cancel button */}
                <Button
                  variant='link'
                  className='text-danger'
                  type='button'
                  disabled={props.isSubmitting}
                  onClick={() => setEditing(false)}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </Button>
              </div>
            </Card.Header>
            <Card.Body>
              <PropertyInfoForm {...props}></PropertyInfoForm>
            </Card.Body>
          </Card>
        </Form>
      )}
    </Formik>
  ) : (
    <Card border='muted'>
      <Card.Header className='d-flex justify-content-between align-items-center'>
        <h5 className='m-0'>1. Property &#38; Contact Information</h5>
        <Button variant='link' type='button' onClick={() => setEditing(true)}>
          <FontAwesomeIcon icon={faEdit} />
        </Button>
      </Card.Header>
      <Card.Body>
        <Container>
          <Row>
            {/* Property details column */}
            <Col>
              <Row>
                <b>Property Details</b>
              </Row>
              <Row>
                <span>ID/Number: {selectedProperty?.property_id || ''}</span>
              </Row>
              <Row>
                <span>Name: {selectedProperty?.property_name || ''}</span>
              </Row>
              <Row>
                <span>Region: {selectedProperty?.region?.name || ''}</span>
              </Row>
              <Row>
                <span>Address: {selectedProperty?.address || ''}</span>
              </Row>
            </Col>

            {/* Owner details column */}
            <Col>
              <Row>
                <b>Owner Details</b>
              </Row>
              <Row>
                <span>Name: {selectedProperty?.owner_name || ''}</span>
              </Row>
              <Row>
                <span>Phone: {selectedProperty?.owner_phone || ''}</span>
              </Row>
              <Row>
                <span>Mobile: {selectedProperty?.owner_mobile || ''}</span>
              </Row>
              <Row>
                <span>Email: {selectedProperty?.owner_email || ''}</span>
              </Row>
            </Col>

            {/* Manager details column */}
            <Col>
              <Row>
                <b>Manager Details</b>
              </Row>
              <Row>
                <span>Name: {selectedProperty?.manager_name || ''}</span>
              </Row>
              <Row>
                <span>Phone: {selectedProperty?.manager_phone || ''}</span>
              </Row>
              <Row>
                <span>Mobile: {selectedProperty?.manager_mobile || ''}</span>
              </Row>
              <Row>
                <span>Email: {selectedProperty?.manager_email || ''}</span>
              </Row>
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
}

export default observer(PropertyInfoCard);
