import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import { useContext, useReducer, useRef, useState } from 'react';
import { Button, Col, Modal, Row, Spinner, Table } from 'react-bootstrap';
import { UserPropertyContext } from '../../../stores/StoreContexts';
import { ApiPost } from '../../../utils/utils';
import Heading from '../../helpers/forms/Heading';
import { errorToast } from '../../helpers/toasts/ToastUtils';
import {
  reducer,
  reducerInitialState
} from '../../production_areas/production_cards/utils/copySpraysReducer';
import ProductionAreaSelect from './ProductionAreaSelect';
import PropertySelect from './PropertySelect';

type Props = {
  propertyId: number;
  productionId: number;
};

const CopySprays = ({ propertyId, productionId }: Props): JSX.Element => {
  const userPropertyStore = useContext(UserPropertyContext);
  const initialState = reducerInitialState();
  const [state, dispatch] = useReducer(reducer, initialState);
  const addButtonRef = useRef<HTMLButtonElement | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const productionArea = userPropertyStore.properties
    ?.filter((property) => {
      return property.id === Number(propertyId);
    })[0]
    ?.property_blocks!.filter((area) => {
      return area.id === Number(productionId);
    })[0];

  return (
    <div>
      <Button
        ref={addButtonRef}
        variant='outline-success'
        onClick={() => {
          setShowModal(!showModal);
        }}
      >
        <FontAwesomeIcon icon={faCopy} /> Copy from existing
      </Button>
      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false);
        }}
        backdrop='static'
        centered
        style={{ border: 'none!important' }}
      >
        <Modal.Header style={{ backgroundColor: '#363743' }}>
          <Modal.Title style={{ color: 'white', fontSize: 20 }}>
            Copy spray products from existing production area
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Select a property and production area to copy spray products from.
            These will be added to {<b>{productionArea?.name}</b>}. Once copied
            you can edit and remove spray products as required.
          </p>
          <Row>
            <Col>
              <Row className='mb-3'>
                <Col>
                  <Heading className='mb-1' level='h6'>
                    Select a Property
                  </Heading>
                  <PropertySelect
                    property={state.property}
                    dispatch={dispatch}
                  />
                </Col>
              </Row>
              <Row className='mb-3'>
                <Col>
                  <Heading className='mb-1' level='h6'>
                    Select a Production Area
                  </Heading>
                  {userPropertyStore.loadingProductionAreas ? (
                    <Row className='pt-4'>
                      <Col></Col>
                      <Col className='text-center'>
                        <Spinner animation='border' size='sm' />
                      </Col>
                      <Col></Col>
                    </Row>
                  ) : (
                    <ProductionAreaSelect
                      currentProductionArea={productionArea}
                      newProductionArea={state.productionArea}
                      property={state.property}
                      dispatch={dispatch}
                    />
                  )}
                </Col>
              </Row>
              {state.productionArea &&
                !userPropertyStore.loadingProductionAreas && (
                  <Row style={{ overflow: 'scroll', height: '14em' }}>
                    <Col>
                      <Table>
                        <thead>
                          <tr>
                            <th>Product Name</th>
                          </tr>
                        </thead>
                        <tbody>
                          {state.productionArea?.block_sprays.map((spray) => {
                            const product = userPropertyStore.products?.find(
                              (product) =>
                                product.licence_no === spray.licence_no
                            );
                            return (
                              <tr>
                                <td className='p-1'>
                                  {product?.name ? (
                                    product.name
                                  ) : (
                                    <i style={{ color: 'gray' }}>
                                      {product?.licence_no}
                                    </i>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setShowModal(false)}
            variant='outline-secondary'
          >
            Cancel
          </Button>
          <Button
            variant='success'
            disabled={
              state.property && state.productionArea && !loading ? false : true
            }
            onClick={async () => {
              setLoading(true);
              const body = {
                sprays: state.productionArea?.block_sprays
              };
              const response = await ApiPost(
                `property/${propertyId}/production-area/${productionId}/block-sprays`,
                body
              );
              if (response?.ok) {
                await userPropertyStore.fetchBlockSprays(
                  Number(propertyId),
                  Number(productionId)
                );
              } else {
                errorToast('Something went wrong');
              }
              setLoading(false);
              setShowModal(false);
            }}
          >
            {loading ? (
              <Spinner
                className='mx-5'
                as='span'
                animation='border'
                size='sm'
                role='status'
              />
            ) : (
              <>Copy Spray Products</>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default observer(CopySprays);
