import { Col, Container, Image, Row } from 'react-bootstrap';

const Sponsors = (): JSX.Element => {
  return (
    <Container fluid className='my-4'>
      <hr />
      <Container className='pt-3'>
        <Row className='justify-content-center align-items-center'>
          <Col className='text-center' xs={5} md={4}>
            <h3 className='float-left font-weight-bold'>Supported By</h3>
          </Col>
          <Col>
            <Row className='justify-content-center align-items-center'>
              <Col className='text-center'>
                <Image
                  src={process.env.REACT_APP_S3_ASSETS + 'croplands-logo.jpg'}
                  alt='Croplands'
                  height='20'
                  className='m-3'
                />
              </Col>
              <Col className='text-center'>
                <Image
                  src={process.env.REACT_APP_S3_ASSETS + 'nzwine-logo.jpg'}
                  alt='Croplands'
                  height='50'
                  className='m-3'
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Sponsors;
